/* PlanDetails component styles */
.plan-details-container {
  max-height: 100vh;
  overflow-y: auto;
  width: 100%;
  padding-bottom: 50px;
}

/* Table Container */
.table-container {
  width: 100%;
  border-collapse: collapse;
}

/* Table Row */
.table-row {
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease;
}

.table-row:hover {
  background-color: #f5f5f5; /* Highlight row on hover */
}

/* Table Cells */
.table-cell {
  flex: 1;
  padding: 8px 0;
  border-right: 1px solid #ddd;
  font-size: 14px;
  text-align:center;
}

.table-cell:last-child {
  border-right: none;
}

/* Header Styling */
.header {
  font-weight: bold;
  color: white;
}

.header .table-cell {
  background-color: #0056b3; /* Blue background for header */
  color: white;
}

/* Button Group Container */
.button-group {
  display: flex;
  gap: 10px;
  justify-content: center;
}

/* Buttons */
.edit-button, .more-details-button {
  width: 90px;  /* Consistent smaller button width */
  padding: 6px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.edit-button {
  background-color: #007bff;
  color: white;
}

.edit-button:hover {
  background-color: #0056b3;
}

.more-details-button {
  background-color: #28a745;
  color: white;
}

.more-details-button:hover {
  background-color: #218838;
}

/* General Title Styling */
.title {
  /* text-align: center; */
  font-size: 24px;  /* Adjust the font size for a prominent title */
  font-weight: 700;  /* Make it bold */
  color: #333;  /* Dark color for contrast */
  letter-spacing: 1px;  /* Add some spacing between letters */
  position: relative;
  margin: 10;
  margin-top: 10px;
}

/* For Modal Titles (like "Edit Return Status" or "Approved At") */
.modal-title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  text-transform: capitalize;  /* Ensure each word starts with capital letter */
}


/* Expanded Returns Section */
.returns-details {
  display: none;
  padding-left: 20px;
  margin-top: 10px;
}

.returns-details.expanded {
  display: block;
}

.returns-list {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Return Items */
.return-item {
  display: flex;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
}

.return-cell {
  flex: 1;
  padding: 6px;
}

.return-item:nth-child(odd) {
  background-color: #f8f9fa;
}

.return-item:nth-child(even) {
  background-color: #e9ecef;
}

/* Edit Modal Styling */
.edit-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  width: 320px;
  max-width: 90%;
}

.edit-modal h4 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Modal Action Buttons */
.modal-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.modal-actions button {
  width: 45%;  /* Buttons side by side */
  padding: 8px 0;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  transition: background-color 0.3s, transform 0.2s;
}

.modal-actions button:hover {
  transform: scale(1.05);
}

.modal-actions button:first-child {
  background-color: #007bff;
  color: white;
}

.modal-actions button:first-child:hover {
  background-color: #0056b3;
}

.modal-actions button:last-child {
  background-color: #f8f9fa;
  color: #333;
  border: 1px solid #ddd;
}

.modal-actions button:last-child:hover {
  background-color: #e9ecef;
}

/* Responsive adjustments for modal */
@media (max-width: 768px) {
  .edit-modal {
    width: 90%;
    max-width: 350px;
  }

  .modal-actions {
    flex-direction: column;
  }

  .modal-actions button {
    width: 100%;
    margin-bottom: 10px;
  }
}

/* Confirmation Dialog */
.MuiDialog-paper {
  padding: 20px;
  width: 90%;
  max-width: 400px;
}

.MuiDialogTitle-root {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.MuiDialogContent-root {
  font-size: 14px;
  color: #555;
}

/* Custom input field styles */
.transaction-id-field,
.date-field {
  margin-bottom: 20px;
}


.background-container {
    padding: 20px;
    background-color: #f0f0f0;
    width: 102%;
    height: 100vh;
}

.heading-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.icon-container {
    display: flex; 
    justify-content: center; 
    align-items: center;
    background: linear-gradient(135deg, #d6aaff, #9b43ce);
    width: 30px;
    height: 30px; 
    margin-right: 10px; 
    border-radius: 5px;
}

.icon {
    font-size: 1rem; 
    color: white; 
}

.heading {
    color: transparent; 
    -webkit-background-clip: text; 
    background-clip: text;
    color: black;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
}

.currentplans-container {
    max-width: 100%;
    overflow-x: auto; 
    overflow-y: hidden; 
    height: 400px; 
    margin-top: 20px; 
}

table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
}

th, td {
    padding: 8px;
    border: 1px solid #ddd;
}

thead {
    background-color: #14ca42;
}

th {
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
}

td {
    vertical-align: middle;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    color: #333;
    border-bottom: 1px solid #ddd;
    text-align: center;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

table tbody tr:hover {
    background-color: blue; 
}

table tbody tr:hover td {
    color: white; 
}

.delete-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.delete-form-body {
  padding: 20px;
}

.close-button {
  font-size: 24px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  /* padding: 10px; */
}

.close-button:hover {
  color: #d10e1e;
}

.cancel-button {
  background-color: #ccc;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #aaa;
}

.confirm-button {
  background-color: red;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 30px;
}

.confirm-button:hover {
  background-color: #FF99CC;
}

.error-message {
  font-size: 14px;
  color: red;
  margin-top: 10px;
}
/* Page container to hold all layout elements */
.page-container {
  display: flex;
  height: 100vh;
  overflow: hidden; 
}

/* Sidebar Container */
.sidebar-container {
  width: 270px; 
  padding: 10px;
  transition: width 0.4s ease; 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100vh;
  position: fixed;
  z-index: 999;
  max-height: 100vh;
  background-color: #2c3e50; /* Sidebar background color */
  overflow-y: auto;
}

.sidebar-container.closed {
  width: 70px;
}

/* Main content */
.main-content {
  padding: 0px;
  transition: margin-left 0.4s ease, width 0.4s ease, margin-top 0.4s ease; 
  flex-grow: 1;
  margin-left: 270px; /* Offset for sidebar width */
  margin-top: 60px; /* Offset for header height */
  width: calc(100% - 270px); /* Adjust width to account for sidebar */
}

.sidebar-container.closed ~ .main-content {
  margin-left: 70px; /* Adjust margin-left when sidebar is collapsed */
  width: calc(100% - 70px); /* Adjust width for collapsed sidebar */
}

/* Header Container */
.header-container {
  padding: 10px 20px;
  height: 60px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 10px;
  z-index: 1000;
  background-color: #34495e; /* Header background color */
  width: calc(100% - 20px); /* Full width minus padding */
  transition: left 0.4s ease, width 0.4s ease; 
}

.header-container.header-expanded {
  left: 270px; 
  width: calc(100% - 270px); 
}

.header-container.header-collapsed {
  left: 70px; 
  width: calc(100% - 70px); 
}

/* Sidebar Logo Container */
.sidebar-logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.sidebar-logo-icon {
  width: 50px;
  height: 50px;
}

.sidebar-company-info {
  display: flex;
  flex-direction: column;
}

.sidebar-company-name {
  margin: 0;
  color: rgb(55, 167, 4);
}

.sidebar-company-tagline {
  margin: 0;
  font-size: 12px;
  color: rgb(55, 167, 4);
}

/* Sidebar Menu */
.sidebar-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; 
  cursor: pointer;
  color: #fff;
  transition: background-color 0.3s;
  position: relative;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
}

.menu-item:hover,
.menu-item.active {
  background-color: #2980b9;
}

.menu-item span {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.sidemenu-part {
  transition: background-color 0.3s ease;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.submenu-item:hover, 
.submenu-item.active {
  background-color: #2980b9; 
  color: white; 
}

.submenu {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  color: #666;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
  opacity: 0;
  max-height: 0;
  font-weight: 600;
}

.submenu-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;
  padding: 8px; 
  border-radius: 4px; 
}

.submenu-item:hover {
  background-color: #2980b9; 
  color: white; 
}

.submenu.submenu-open {
  opacity: 1;
  max-height: 150px;
  margin-left: 20px;
  margin-top: 10px;
}

.sidebar-container.closed .menu-item span {
  display: none;
}

.sidebar-container.closed .submenu {
  display: none;
}

/* Header Content */
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Actions Container */
.actions-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.menu-icon, .mail-icon, .notification-icon, .power-icon {
  font-size: 24px;
  color: #fff;
}

.user-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.user-name {
  margin-right: 10px;
}

.dropdown-icon {
  font-size: 20px;
  color: #fff;
}
